import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {patternFill} from './header.module.css';

const Header = () => {
  return (
    <header className='container-fluid bg-pattern'>
      {/* <div className="row">
        <div className="col">
          <div className={'bg-pattern ' + patternFill}></div>
        </div>
      </div> */}
      <div className='row pt-5 pb-5'>
        <div className="col">
          <StaticImage src="../../images/logo-header.png" alt="Queertech.io Logo" />
        </div>
      </div>
    </header>
  )
}

export default Header;