import * as React from 'react'
// import '../gallery-item.scss';
import { graphql } from "gatsby";
import Layout from '../components/layout/layout';
import Header from '../components/header/header';
import ArtworkTile from '../components/artwork-tile/artwork-tile';

const ArtworksIndex = ({data}) => {
  const tiles = data.allMarkdownRemark.edges;

  const getLinks = (links) => {
    links = links || [];
    return (
      <span>
        |&nbsp;
      {links.map(x => <a href={x.link}>{x.text} | </a> )}
      </span>
    )
  }
  return (
    <Layout pageTitle={`Queertech 2022 AND/OR`} description={`Queertech 2022 AND/OR`}>
      <Header />
      <main>
        <div className='container pt-5 pb-5 text-center'>
          <h1 className='text-pixel header-text-pink'>Queertech 2022 is now closed for the year. Thanks for visiting &lt;3</h1>
          <p>Thanks to all our participating artists</p>
          {tiles.map(x => {
            return (
              <p>
                <span className='fs-3'>{x.node.frontmatter.artist}</span>
                <br />
                {getLinks(x.node.frontmatter.links)}
              </p>
            )
          })}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            slug
            artist
            title
            links {
              text
              link
            }
            hero_image {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ArtworksIndex